import '../shared/index';
import './components';
import './stores';
import {
  makeCarousel,
  makeUnmountedCarousel,
} from '../../auto/utils/carousel/splide';
import { makeAllDdmOffersListSyncService } from '../../auto/services/DdmServiceV2/makeAllDdmOffersListSyncService';
import { DDMAdapter } from '../../auto/services/ddm_service/adapters/ddmAdapter';
import { addExceptionModelsToChannelData } from '../../auto/services/ddm_service/utils';
import DdmServiceV2 from '../../auto/services/DdmServiceV2/DdmServiceV2';
import fcaServiceApi from '../../auto/utils/fcaServiceApi';
import railsDateToChronometer from '../../auto/utils/railsDateToChronometer';
import isMobile from '../../auto/utils/isMobile';
import DDMDataLayerHandler from '../../auto/utils/DDMDataLayerHandler';

window.makeCarousel = makeCarousel;
window.makeUnmountedCarousel = makeUnmountedCarousel;
window.railsDateToChronometer = railsDateToChronometer;
window.isMobile = isMobile;

// Configurações para carrossel de ofertas
window.DDMAdapter = DDMAdapter;
window.autoServiceApi = DdmServiceV2; // API ofertas do Autódromo
window.serviceFca = fcaServiceApi; // API ofertas do marketplace
window.ddmOffersListSyncService = makeAllDdmOffersListSyncService();
window.DDMDataLayerHandler = new DDMDataLayerHandler();
window.helpers = {
  ...window.helpers,
  addExceptionModelsToChannelData,
};
