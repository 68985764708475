import { Component } from 'react';
import PropTypes from 'prop-types';
import TimeMissingFiatV2 from '../../TimeMissingFiatV2';
import FeaturesDisplay from '../DDM/FeaturesDisplay';

export default class NewOffersFiatV2 extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { id, title, brand, category, price } = this.props;

    const offer = {
      id,
      name: title,
      brand,
      category,
      price,
    };

    window.DDMDataLayerHandler.addSelectOfferEvent(offer);
  }

  compareTitles(title, subtitle) {
    const fcaApiBrand = document.querySelector("meta[name='fca_api_brand']");
    const brand = fcaApiBrand ? fcaApiBrand.content : '';

    return title === subtitle ? brand : subtitle;
  }

  imageAlt() {
    const { title, subtitle } = this.props;

    return `Imagem do ${title} ${this.compareTitles(title, subtitle)}`;
  }

  formatExpiredAt(date) {
    return window.railsDateToChronometer(date);
  }

  offerFeatured(featured) {
    const { baseType, offerType } = this.props;

    if (baseType === 'Autodromo' && offerType === 2) {
      const pattern = /R\$\s\d{1,3}(?:\.\d{3})*(?:,\d{2})?/;
      const value = featured.match(pattern);
      const valueMessage = featured.split(value);

      return (
        <div className="card__trigger-value-in-cash">
          {valueMessage[0]} <span>{value}</span> {valueMessage[1]}
        </div>
      );
    }

    return featured;
  }

  render() {
    const {
      title,
      subtitle,
      image,
      link,
      sealList,
      offer,
      showBadge,
      badge,
      showExpiredAt,
      expiredAt,
    } = this.props;

    const {
      secondary_call,
      main_call,
      featured,
      description,
      features,
      has_features_list,
    } = offer;

    return (
      <a
        className="offers-fca-card__link"
        href={link}
        onClick={this.handleClick}
      >
        <div className="card offers-fca-card">
          {showExpiredAt && (
            <TimeMissingFiatV2 date={this.formatExpiredAt(expiredAt)} />
          )}

          <div className="card__header">
            <img
              className="offers-fca-card__image"
              src={image}
              alt={this.imageAlt()}
              width="223"
              height="128"
            />
          </div>

          <div className="card__content offers-fca-card__content">
            <div className="card__content-header">
              <div
                className="card__title offer-card__title"
                data-match-height="offer-card__title"
              >
                {title}
              </div>
              <div
                className="card__subtitle offer-card__subtitle"
                data-match-height="offer-card__subtitle"
              >
                {this.compareTitles(title, subtitle)}
              </div>
            </div>

            {showBadge && <div className="offers-fca-card__badge">{badge}</div>}

            <div>
              <ul
                className="card__list list offers-fca-card__list"
                data-match-height="offers-fca-card__list"
              >
                {sealList.slice(0, 3).map((item, index) => (
                  <li key={index} className="offers-fca-card__list-item">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="card__footer">
            <div
              className="card__box-offer offers-fca-card__prices"
              data-match-height="offers-fca-card__prices"
            >
              {secondary_call && (
                <p className="card__trigger-starting-price">{secondary_call}</p>
              )}
              {main_call && (
                <p className="card__title footer-card__title">{main_call}</p>
              )}
              {featured && (
                <div className="card__trigger-value">
                  {this.offerFeatured(featured)}
                </div>
              )}
              {description && (
                <div className="card__trigger-description">{description}</div>
              )}

              {has_features_list && features && (
                <FeaturesDisplay features={features} />
              )}
            </div>
            <button
              type="button"
              className="btn button button--block button--large button--primary card__cta"
            >
              <div className="card__cta-background" />
              <div className="card__cta-text">Eu quero</div>
            </button>
          </div>
        </div>
      </a>
    );
  }
}

NewOffersFiatV2.defaultProps = {
  id: '',
  title: '',
  subtitle: '',
  image: '',
  link: '',
  sealList: [],
  offer: {},
  showBadge: false,
  badge: '',
  showExpiredAt: false,
  expiredAt: '',
  baseType: '',
  offerType: '',
  brand: '',
  category: '',
  price: '',
};

NewOffersFiatV2.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  sealList: PropTypes.arrayOf(PropTypes.string),
  offer: PropTypes.shape({
    secondary_call: PropTypes.string,
    main_call: PropTypes.string,
    featured: PropTypes.string,
    description: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    has_features_list: PropTypes.bool,
  }),
  showBadge: PropTypes.bool,
  badge: PropTypes.string,
  showExpiredAt: PropTypes.bool,
  expiredAt: PropTypes.string,
  baseType: PropTypes.string,
  offerType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  brand: PropTypes.string,
  category: PropTypes.string,
  price: PropTypes.number,
};
